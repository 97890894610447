
import { bannerCode, googleFontCode, footerCode, aboutCode } from './codeBuilder';
import { htmlwrapper, baseCode } from './baseCode';

const generateCode = (templateCode, data) => {

  const currentDate = new Date;

  const socialIconsInputs = [data.FB_URL.value, data.TW_URL.value, data.IG_URL.value, data.LI_URL.value];
  let socialIcons = [];
  if (socialIconsInputs.length > 0) {
    socialIconsInputs.forEach((url, i) => {
      if (socialIconsInputs[i] !== '') {
        switch (i) {
          case 0: socialIcons.push('<a href="{{FB_URL}}" target="_blank" rel="noreferrer noopener"><i class="fab fa-facebook-square"></i></a>'); break;
          case 1: socialIcons.push('<a href="{{TW_URL}}" target="_blank" rel="noreferrer noopener"><i class="fab fa-twitter-square"></i></a>'); break;
          case 2: socialIcons.push('<a href="{{IG_URL}}" target="_blank" rel="noreferrer noopener"><i class="fab fa-instagram"></i></a>'); break;
          case 3: socialIcons.push('<a href="{{LI_URL}}" target="_blank" rel="noreferrer noopener"><i class="fab fa-linkedin"></i></a>'); break;
          default: { }
        }
      }
    });
  }

  const ogMetaTags = [data.OG_TITLE.value, data.OG_DESCRIPTION.value, data.OG_IMAGE_URL.value];
  let ogMetaTagsCode = [];
  if (ogMetaTags.length > 0) {
    ogMetaTags.forEach((tag, i) => {
      if (ogMetaTags[i] !== '') {
        switch (i) {
          case 0: ogMetaTagsCode.push(`<meta property="og:title" content="{{OG_TITLE}}" />`); break;
          case 1: ogMetaTagsCode.push(`<meta property="og:description" content="{{OG_DESCRIPTION}}" />`); break;
          case 2: ogMetaTagsCode.push(`<meta property="og:image" content="{{OG_IMAGE_URL}}" />`); break;
          default: { }
        }
      }
    });
  }

  const bannerDataInputs = [data.BANNER_URL_1.value, data.BANNER_URL_2.value, data.BANNER_URL_3.value, data.BANNER_URL_4.value];

  const fontInputs = [data.HEADINGS_GOOGLE_FONT.value, data.BODY_GOOGLE_FONT.value];

  const aboutSectionInputs = [data.ABOUT_HEADING_TEXT.value, data.ABOUT_BODY_TEXT.value, data.VIDEO_SOURCE_URL.value, data.IMAGE_SOURCE_URL.value];

  let code = `${htmlwrapper}
<!-- Generated by Job Skin Builder v1.1.0 -->
<!-- Date: ${currentDate} -->
<!-- Single-Step Application Compatible -->
<head>
  ${baseCode.meta}
  ${ogMetaTagsCode.length > 0 ? ogMetaTagsCode.join('\n') : ''}
  ${baseCode.favicon}
  ${baseCode.boilerplate}
  ${baseCode.fonts}
  ${googleFontCode(fontInputs)}
  ${baseCode.css}
</head>
<body>
  ${baseCode.compatibility_notice}
  ${baseCode.header}
  ${bannerDataInputs.length > 0 ? bannerCode(bannerDataInputs) : ''}
  ${baseCode.content}
  ${baseCode.additional_sections}
  ${aboutSectionInputs.join('') !== '' ? aboutCode([data.VIDEO_SOURCE_URL.value, data.IMAGE_SOURCE_URL.value]) : ''}
  ${socialIconsInputs.length > 0 ? footerCode(socialIcons, data.POWERED_BY_SCOUT.value, data.POWERED_BY_SCOUT_COUNTRY.value) : ''}
  ${baseCode.boilerplate_js}
  ${baseCode.optional_js}
  ${baseCode.onload_js}
</body>
</html>
`;

  if (data.HEADINGS_GOOGLE_FONT.value.trim() !== '') {
    data.HEADINGS_FONT.value = `'${data.HEADINGS_GOOGLE_FONT.value}', arial, helvetica, sans-serif`;
  }

  if (data.BODY_GOOGLE_FONT.value.trim() !== '') {
    data.BODY_FONT.value = `'${data.BODY_GOOGLE_FONT.value}', arial, helvetica, sans-serif`;
  }

  for (let key in data) {
    const placeholder = '{{' + key + '}}';
    const value = data[key].value !== '' ? data[key].value : ' ';
    code = code.split(placeholder).join(value);
  }

  return code;
}

export default generateCode;