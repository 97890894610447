import React from 'react';

const Footer = props => {
  return (
    <div className="bg-dark text-white text-center pb-2 pt-2">
      {new Date().getFullYear()} &copy; Scout Talent
    </div>
  )
}

export default Footer;